<template>
  <div>
    <vx-card>
      <h2 class="mb-5">Past Subscriptions List</h2>
      <h6 class="mb-5">Customer Name: {{ this.userName }}</h6>
      <div v-if="this.pastSubscriptionData != null" class="mb-5">
        <vs-table ref="table" :sst="true" :data="pastSubscriptionData">
          <template slot="thead">
            <vs-th>Package</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Start Date</vs-th>
            <vs-th>End Date</vs-th>
            <vs-th>Package Type</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.type">
                {{ tr.type | capitalize }}
              </vs-td>
              <vs-td :data="tr.details.endDate">
                {{
                  moment(tr.details.endDate) >= moment() ? "Active" : "Expired"
                }}
              </vs-td>
              <vs-td :data="tr.details.startDate">
                {{ moment(tr.details.startDate).format("MMMM DD YYYY") }}
              </vs-td>
              <vs-td :data="tr.details.endDate">
                <span v-if="tr.details.endDate">{{
                  moment(tr.details.endDate).format("MMMM DD YYYY")
                }}</span>
                <span v-else> - </span>
              </vs-td>
              <vs-td :data="tr.details.price">
                ${{ tr.details.price }}/per child
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <div v-else>
        <h4>Customer does not have any past subscription.</h4>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      pastSubscriptionData: [],
      userName: ""
    };
  },
  methods: {
    ...mapActions("userSubscription", ["getPastSubscriptionsDetails"]),
    moment(date) {
      return moment(date);
    },
    getPastSubscriptionsData(id) {
      this.getPastSubscriptionsDetails(id).then(res => {
        this.pastSubscriptionData = res.data.data.pastSubscriptions;
        this.userName = res.data.data.userName;
      });
    }
  },

  created() {
    let userId = this.$route.params.id;
    this.getPastSubscriptionsData(userId);
  }
};
</script>
